package com.binayshaw7777.myportfolio.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontOpticalSizing
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fontOpticalSizing
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun StrokeChips(
    modifier: Modifier = Modifier,
    text: String,
) {
    Box(
        modifier = Modifier
            .padding(topBottom = 6.px, leftRight = 12.px)
            .borderRadius(25.px)
            .width(Width.FitContent)
            .border(width = 0.85.px, style = LineStyle.Solid)
            .then(modifier),
    ) {
        SpanText(
            text = text,
            modifier = Modifier.fontSize(14.px)
                .fontWeight(401)
                .fontOpticalSizing(FontOpticalSizing.Auto)
        )
    }
}