package com.binayshaw7777.myportfolio.components.widgets

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.utils.Constants
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.widgets.CircularStrokeImage
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.flexDirection
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.cssRem

@Composable
fun SocialMediaIcons() {
    Row (
        modifier = Modifier.padding()
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Row)
    ) {
        Link(
            Constants.GITHUB_URL
        ) {
            CircularStrokeImage(
                src = Res.GITHUB_ICON,
                modifier = Modifier.margin(all = 0.6.cssRem)
            )
        }
        Link(
            Constants.LINKEDIN_URL
        ) {
            CircularStrokeImage(
                src = Res.LINKEDIN_ICON,
                modifier = Modifier.margin(all = 0.6.cssRem)
            )
        }
        Link(
            Constants.TWITTER_URL
        ) {
            CircularStrokeImage(
                src = Res.X_ICON,
                modifier = Modifier.margin(all = 0.6.cssRem)
            )
        }
        Link(
            Constants.BEHANCE_URL
        ) {
            CircularStrokeImage(
                src = Res.BEHANCE_ICON,
                modifier = Modifier.margin(all = 0.6.cssRem)
            )
        }
        Link(
            Constants.MEDIUM_URL
        ) {
            CircularStrokeImage(
                src = Res.MEDIUM_ICON,
                modifier = Modifier.margin(all = 0.6.cssRem)
            )
        }
    }
}