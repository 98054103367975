package com.binayshaw7777.myportfolio.utils

object Strings {
    const val HI_IM = "Hi, I'm"
    const val BINAY_SHAW = "Binay Shaw."
    const val NICE_TO_MEET_YOU = "Nice to meet you."
    const val ABOUT_ME = "About Me."
    const val ABOUT_ME_FIRST_PARA = "I’m a self-taught software engineer and designer."
    const val ABOUT_ME_SECOND_PARA = "I have been working in the tech industry for over a year. Let's connect."
    const val FEATURED_PROJECTS = "Featured Projects. Featured Projects. Featured Projects."
    const val KOLKATA_INDIA = "Kolkata, India."
    const val MY_MAIL = "binayshaw7777@gmail.com"
    const val COPYRIGHT_2024_BINAY_SHAW = "© 2024 Binay Shaw"
    const val CONNECT_WITH_ME = "Connect with me"
    const val DOWNLOAD_MY_RESUME = "Download my Resume"
    const val MY_RESUME = "My Resume"
    const val JUSTAP = "JusTap"
    const val JUSTAP_DESCRIPTION = "JusTap is a mobile app for the secure and easy exchange of contact information and social links using QR codes, replacing traditional business cards."
    const val READBUD = "ReadBud"
    const val READBUD_DESCRIPTION = "ReadBud is a mobile app for reading and sharing stories, articles, and blogs. It is a platform for writers to share their stories and for readers to discover new stories."
    const val KOTLIN = "Kotlin"
    const val JETPACK_COMPOSE = "Jetpack Compose"
    const val FIREBASE = "Firebase"
    const val ML_KIT = "ML Kit"
    const val EXPERIENCE = "Experience"
    const val IONAGE = "Ionage"
    const val SOFTWARE_DEVELOPER = "Software Developer 1"
    const val SOFTWARE_DEVELOPER_INTERN = "Software Developer Intern"
    const val IONAGE_DURATION_FTE = "2024 - Present"
    const val IONAGE_DURATION_INTERN = "2023 - 2024"
    const val HUMARA_NAGAR = "Humara Nagar"
    const val ANDROID_DEVELOPER_INTERN = "Android Developer Intern"
    const val HUMARA_NAGAR_DURATION = "2023 - 23"
    const val EDVORA = "Edvora"
    const val EDVORA_DURATION = "2023 - 23"
}