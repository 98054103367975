package com.binayshaw7777.myportfolio.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.binayshaw7777.myportfolio.components.sections.Footer
import com.binayshaw7777.myportfolio.components.sections.NavHeader
import com.binayshaw7777.myportfolio.components.sections.NavHeaderStyle
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.vw

val PageContentStyle by ComponentStyle {
    base {
        Modifier.fillMaxSize().padding(leftRight = 2.cssRem, top = 4.cssRem)
            .scrollBehavior(ScrollBehavior.Smooth)
    }
    Breakpoint.MD {
        Modifier.maxWidth(60.cssRem)
    }
}

@Composable
fun PageLayout(title: String = "", content: @Composable ColumnScope.() -> Unit) {

    LaunchedEffect(title) {
        if (title.isNotEmpty())
            document.title = "Binay Shaw - $title"
        else document.title = "Binay Shaw"
    }

    Box(
        Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            .gridTemplateRows { size(1.fr); size(minContent) },
        contentAlignment = Alignment.Center
    ) {
        Column(
            // Isolate the content, because otherwise the absolute-positioned SVG above will render on top of it.
            // This is confusing but how browsers work. Read up on stacking contexts for more info.
            // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
            // Some people might have used z-index instead, but best practice is to avoid that if possible, because
            // as a site gets complex, Z-fighting can be a huge pain to track down.
            Modifier.fillMaxSize().gridRow(1),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            NavHeader()
            Column(
                PageContentStyle.toModifier(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
            HorizontalDivider(modifier = Modifier.maxWidth(100.vw).opacity(0.2f))
        }
        // Associate the footer with the row that will get pushed off the bottom of the page if it can't fit.
        Footer(NavHeaderStyle.toModifier().fillMaxWidth().gridRow(2))
    }
}
