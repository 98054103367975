package com.binayshaw7777.myportfolio.utils

object Res {

    const val SCROLL_DOWN_CIRCULAR_TEXT = "images/scroll_down_circular_text.svg"
    const val SCROLL_DOWN_SWITCH_ICON = "images/scroll_down_switch_icon.svg"
    const val INSTAGRAM_ICON = "images/instagram.svg"
    const val LINKEDIN_ICON = "images/linkedIn.svg"
    const val GITHUB_ICON = "images/github.svg"
    const val TWITTER_ICON = "images/twitter.svg"
    const val X_ICON = "images/x.svg"
    const val BEHANCE_ICON = "images/behance.svg"
    const val MEDIUM_ICON = "images/medium.svg"
    const val FIGMA_ICON = "images/figma.svg"
    const val MY_PICTURE = "images/my_picture_1_1.png"

    const val IONAGE_WORK_EXP = "images/ionage_work_exp.png"
    const val HUMARA_NAGAR_WORK_EXP = "images/humara_nagar_work_exp.png"
    const val EDVORA_WORK_EXP = "images/edvora_work_exp.png"

    const val TOP_RIGHT_ARROW = "images/top_right_arrow.svg"
    const val CURVED_HEAD_ARROW = "images/curved_head_arrow.svg"
}