package com.binayshaw7777.myportfolio.widgets

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.ExperienceDurationFontStyle
import com.binayshaw7777.myportfolio.ExperienceOrganizationFontStyle
import com.binayshaw7777.myportfolio.ExperienceRoleFontStyle
import com.binayshaw7777.myportfolio.GrayscaleHoverElementStyle
import com.binayshaw7777.myportfolio.utils.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem



@Composable
fun ExperienceBox(
    modifier: Modifier = Modifier,
    imageRes: String,
    imageWidth: Int,
    imageHeight: Int,
    organizationName: String,
    jobTitle: String,
    duration: String
) {
    Row(
        modifier = Modifier.fillMaxWidth().fontFamily(Constants.SYNE).margin(topBottom = 2.cssRem).then(modifier),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            modifier = Modifier.fillMaxHeight().display(DisplayStyle.Flex).flexWrap(FlexWrap.Wrap),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                src = imageRes,
                width = imageWidth,
                height = imageHeight,
                modifier = GrayscaleHoverElementStyle.toModifier().margin(right = 2.cssRem)
            )
            Column(
                verticalArrangement = Arrangement.SpaceBetween
            ) {
                SpanText(
                    organizationName,
                    modifier = ExperienceOrganizationFontStyle.toModifier()
                )
                SpanText(
                    jobTitle,
                    modifier = ExperienceRoleFontStyle.toModifier()
                )
            }
        }
        Spacer()
        SpanText(
            duration,
            modifier = ExperienceDurationFontStyle.toModifier()
        )
    }
}