package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.AboutMeFontStyle
import com.binayshaw7777.myportfolio.ButtonStyle
import com.binayshaw7777.myportfolio.GrowStyle
import com.binayshaw7777.myportfolio.utils.Constants
import com.binayshaw7777.myportfolio.utils.CustomColorSchemes
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.utils.Strings
import com.binayshaw7777.myportfolio.widgets.AppearanceAwareImage
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun AboutMe() {
    val pageContent = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    val aboutMeImageSize = when (breakpoint) {
        Breakpoint.ZERO -> 200
        Breakpoint.SM -> 225
        Breakpoint.MD -> 250
        Breakpoint.LG -> 300
        else -> 300
    }

    val aboutMeImageContentAlignment = when (breakpoint) {
        Breakpoint.ZERO -> Alignment.Center
        Breakpoint.SM -> Alignment.CenterStart
        else -> Alignment.CenterStart
    }

    Box(
        modifier = Modifier.fillMaxWidth().minHeight(100.vh).margin(top = 100.px).id("about"),
        contentAlignment = Alignment.Center
    ) {

        SimpleGrid(
            modifier = Modifier.fillMaxWidth(),
            numColumns = numColumns(base = 1, sm = 2),
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = aboutMeImageContentAlignment
            ) {
                Image(
                    src = Res.MY_PICTURE,
                    width = aboutMeImageSize,
                    height = aboutMeImageSize,
                    modifier = GrowStyle.toModifier()
                        .pointerEvents(PointerEvents.None)
                        .alignSelf(AlignSelf.Center)
                        .padding(topBottom = 20.px)
                        .height(auto)
                )
            }

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(100.vh)
            ) {
                Column {
                    SpanText(Strings.ABOUT_ME, modifier = Modifier.fontSize(14.px).color(Colors.Gray))
                    SpanText(
                        Strings.ABOUT_ME_FIRST_PARA,
                        modifier = AboutMeFontStyle.toModifier()

                    )
                    SpanText(
                        Strings.ABOUT_ME_SECOND_PARA,
                        modifier = AboutMeFontStyle.toModifier()
                    )
                }

                Button(
                    onClick = {
                        pageContent.router.navigateTo(Constants.RESUME_URL)
                    },
                    colorScheme = CustomColorSchemes.BlackAndWhite,
                    size = ButtonSize.MD,
                    modifier = ButtonStyle
                        .toModifier()
                        .borderRadius(20.px)
                ) {
                    SpanText(
                        Strings.MY_RESUME,
                        modifier = Modifier
                            .fontFamily(Constants.SYNE)
                            .fontWeight(400)
                            .color(
                                when (ColorMode.current) {
                                    ColorMode.LIGHT -> Colors.White
                                    ColorMode.DARK -> Colors.Black
                                }
                            )
                            .margin(right = 10.px)
                    )
                    AppearanceAwareImage(
                        src = Res.TOP_RIGHT_ARROW
                    )
                }
            }
        }
    }
}