package com.binayshaw7777.myportfolio

import com.binayshaw7777.myportfolio.utils.Constants
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.css.TransitionTimingFunction
import com.varabyte.kobweb.compose.css.functions.grayscale
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.modifyComponentStyleBase
import org.jetbrains.compose.web.css.*

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily(
                "Inter",
                "Syne",
                "Darker Grotesque",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen",
                "Ubuntu",
                "Cantarell",
                "Fira Sans",
                "Droid Sans",
                "Helvetica Neue",
                "sans-serif"
            )
            .fontSize(18.px)
            .lineHeight(1.5)
    }

    // Silk dividers only extend 90% by default; we want full width dividers in our site
    ctx.theme.modifyComponentStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}

val HeadlineTextStyle by ComponentStyle.base {
    Modifier
        .fontSize(3.cssRem)
        .textAlign(TextAlign.Start)
        .lineHeight(1.2) //1.5x doesn't look as good on very large text
}

val SubheadlineTextStyle by ComponentStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .textAlign(TextAlign.Start)
        .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.8f))
}

val CircleButtonVariant by ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val UncoloredButtonVariant by ButtonStyle.addVariantBase {
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
}

/**
 * Animations
 */

val Rotate by Keyframes {
    100.percent {
        Modifier
            .transform { rotate(1.turn) }
    }
}


val MarqueeStyle by ComponentStyle {
    base {
        Modifier
            .position(Position.Relative)
            .overflow(Overflow.Hidden)
            .minWidth(200.vw)
            .animation(
                MarqueeKeyFrames.toAnimation(
                    colorMode,
                    duration = 10.s,
                    timingFunction = AnimationTimingFunction.Linear,
                    iterationCount = AnimationIterationCount.Infinite
                )
            )
            .display(DisplayStyle.Flex)
            .justifyContent(JustifyContent.SpaceBetween)
    }
//    hover {
//        Modifier
//            .animation(CSSAnimation(name = "MarqueeAnimation", playState = AnimationPlayState.Paused))
//    }
}
val MarqueeKeyFrames by Keyframes {
    0.percent {
        Modifier
            .transform { translateX(0.px) }
    }
    100.percent {
        Modifier
            .transform { translateX((-50).percent) }
    }
}


val UnderlineAnimationLightStyle by ComponentStyle {
    base {
        Modifier
            .padding(topBottom = 10.px)
            .position(Position.Relative)
    }
    after {
        Modifier
            .background(Colors.Black)
            .bottom(0.px)
            .content("")
            .display(DisplayStyle.Block)
            .height(2.px)
            .left(50.percent)
            .position(Position.Absolute)
            .transition(
                CSSTransition("width", 0.3.s, TransitionTimingFunction.Ease, 0.s),
                CSSTransition("left", 0.3.s, TransitionTimingFunction.Ease, 0.s)
            )
            .width(0.px)
    }
    cssRule(":hover:after") {
        Modifier
            .fillMaxWidth()
            .left(0.px)
    }
}
val UnderlineAnimationDarkStyle by ComponentStyle {
    base {
        Modifier
            .padding(topBottom = 10.px)
            .position(Position.Relative)
    }
    after {
        Modifier
            .background(Colors.White)
            .bottom(0.px)
            .content("")
            .display(DisplayStyle.Block)
            .height(2.px)
            .left(50.percent)
            .position(Position.Absolute)
            .transition(
                CSSTransition("width", 0.3.s, TransitionTimingFunction.Ease, 0.s),
                CSSTransition("left", 0.3.s, TransitionTimingFunction.Ease, 0.s)
            )
            .width(0.px)
    }
    cssRule(":hover:after") {
        Modifier
            .fillMaxWidth()
            .left(0.px)
    }
}

val ButtonStyle by ComponentStyle {
    base {
        Modifier.padding(leftRight = 20.px)
            .margin(top = 30.px)
            .transition(CSSTransition(property = TransitionProperty.All, duration = 300.ms))
    }
    hover {
        Modifier.padding(leftRight = 30.px)
    }
    Breakpoint.SM {
        Modifier.margin(top = 40.px)
    }
    Breakpoint.MD {
        Modifier.margin(top = 50.px)
    }
}

val HoverableTextStyle by ComponentStyle {
    base {
        Modifier
            .color(
                when (colorMode) {
                    ColorMode.LIGHT -> Color.rgb(0x111414)
                    ColorMode.DARK -> Color.rgb(0xF5F5F5)
                }
            )
            .opacity(0.4f)
    }
    hover {
        Modifier
            .opacity(0.8f)
    }
}

val JusTapDivStyle by ComponentStyle {
    cssRule("#justap") {
        Modifier
            .display(DisplayStyle.None)
            .opacity(0)
            .animation(SlideInOut.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }
    cssRule(":hover #justap") {
        Modifier
            .display(DisplayStyle.Block)
            .animation(SlideIn.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }
}

val ReadBudDivStyle by ComponentStyle {
    cssRule("#readbud") {
        Modifier
            .display(DisplayStyle.None)
            .opacity(0)
            .animation(SlideInOut.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }
    cssRule(":hover #readbud") {
        Modifier
            .display(DisplayStyle.Block)
            .animation(SlideIn.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }
}


val SlideInOut by Keyframes {
    0.percent {
        Modifier
            .opacity(0)
            .transform { translateX(100.percent) }
    }
    100.percent {
        Modifier
            .opacity(1)
            .transform { translateX(0.percent) }
    }
}
val SlideIn by Keyframes {
    0.percent {
        Modifier
            .opacity(0)
            .transform { translateX(100.percent) }
    }
    100.percent {
        Modifier
            .opacity(1)
            .transform { translateX(0.percent) }
    }
}


val GrayscaleHoverElementStyle by ComponentStyle {
    hover {
        Modifier
            .animation(GrayscaleIn.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }

    cssRule(":not(:hover)") {
        Modifier
            .animation(GrayscaleOut.toAnimation(colorMode, duration = 0.5.s, fillMode = AnimationFillMode.Forwards))
    }
}
val GrayscaleIn by Keyframes {
    from {
        Modifier
            .filter(grayscale(0.percent))
    }
    to {
        Modifier
            .filter(grayscale(100.percent))
    }
}
val GrayscaleOut by Keyframes {
    from {
        Modifier
            .filter(grayscale(100.percent))
    }
    to {
        Modifier
            .filter(grayscale(0.percent))
    }
}

val GrowStyle by ComponentStyle {
    base {
        Modifier
            .transition(CSSTransition("all", 0.2.s, TransitionTimingFunction.EaseInOut))
    }
    hover {
        Modifier
            .transform { scale(1.01) }
    }
}


val TextAnimationKeyframes by Keyframes {
    0.percent {
        Modifier
            .margin(top = 50.px)
            .opacity(0)
    }
    100.percent {
        Modifier
            .margin(top = 0.px)
            .opacity(1)
    }
}


// HERO


val HeroSectionLayoutStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth().minHeight(50.vh)
    }
    Breakpoint.SM {
        Modifier.fillMaxWidth().minHeight(60.vh)
    }
    Breakpoint.MD {
        Modifier.fillMaxWidth().minHeight(80.vh)
    }
}

val HeroSectionFontStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(35.px)
            .fontFamily(Constants.SYNE)
            .fontOpticalSizing(FontOpticalSizing.Auto)
            .fontStyle(FontStyle.Normal)
            .fontWeight(700)
            .lineHeight(1.2)
    }
    Breakpoint.SM {
        Modifier
            .fontSize(60.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(100.px)
    }
}

val SectionTitleFontStyle by ComponentStyle {
    base {
        Modifier.fontFamily(Constants.INTER)
            .fontSize(2.5.cssRem).maxWidth(100.vw).fontWeight(FontWeight.Black)
            .margin(bottom = 0.5.cssRem)
    }
    Breakpoint.SM {
        Modifier.fontSize(3.5.cssRem)
            .margin(bottom = 0.75.cssRem)
    }
    Breakpoint.MD {
        Modifier.fontSize(5.cssRem)
            .margin(bottom = 1.cssRem)
    }
}


// Experience Styles

val ExperienceDurationFontStyle by ComponentStyle {
    base {
        Modifier.fontFamily(Constants.DARKER_GROTESQUE)
            .opacity(0.8f)
            .fontWeight(700)
            .fontSize(1.cssRem)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.4.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(1.75.cssRem)
    }
}

val ExperienceOrganizationFontStyle by ComponentStyle {
    base {
        Modifier.fontSize(1.cssRem).fontWeight(FontWeight.Bold)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.5.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(2.cssRem)
    }
}

// About Me Styles

val AboutMeFontStyle by ComponentStyle {
    base {
        Modifier
            .fontFamily(Constants.SYNE)
            .fontSize(16.px)
            .fontWeight(600)
            .margin(topBottom = 5.px)
    }
    Breakpoint.SM {
        Modifier
            .fontSize(18.px)
            .margin(topBottom = 10.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(20.px)
            .margin(topBottom = 20.px)
    }
    Breakpoint.LG {
        Modifier
            .fontSize(22.px)
    }
}


val ExperienceRoleFontStyle by ComponentStyle {
    base {
        Modifier.fontSize(1.cssRem)
            .fontFamily(Constants.DARKER_GROTESQUE)
            .opacity(0.5f)
            .fontWeight(600)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.1.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(1.25.cssRem)
    }
}

// Footer Styles

val FooterStyle by ComponentStyle.base {
    Modifier.padding(topBottom = 1.5.cssRem, leftRight = 10.percent)
}