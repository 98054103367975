package com.binayshaw7777.myportfolio.pages

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.components.layouts.PageLayout
import com.binayshaw7777.myportfolio.components.sections.AboutMe
import com.binayshaw7777.myportfolio.components.sections.Experience
import com.binayshaw7777.myportfolio.components.sections.Hero
import com.binayshaw7777.myportfolio.components.sections.Project
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page

@Page
@Composable
fun HomePage() {
    PageLayout {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            Hero()
            AboutMe()
            Project()
            Experience()
        }
    }
}
