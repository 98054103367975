package com.binayshaw7777.myportfolio.utils

object Constants {
    const val RESUME_URL = "https://drive.google.com/file/d/16eWmOzXnZ1AtqBlYldhMbBiJCySd1jWn/view?usp=sharing"
    const val LINKEDIN_URL = "https://www.linkedin.com/in/binayshaw7777/"
    const val GITHUB_URL = "https://github.com/binayshaw7777"
    const val TWITTER_URL = "https://twitter.com/binayplays7777"
    const val BEHANCE_URL = "https://www.behance.net/GAMIX7"
    const val MEDIUM_URL = "https://medium.com/@binayshaw7777"

    const val JUSTAP_URL = "https://play.google.com/store/apps/details?id=com.binay.shaw.justap"
    const val READBUD_URL = "https://play.google.com/store/apps/details?id=com.binayshaw7777.readbud"

    const val INTER = "Inter"
    const val SYNE = "Syne"
    const val DARKER_GROTESQUE = "Darker Grotesque"
}