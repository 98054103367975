package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.HeroSectionFontStyle
import com.binayshaw7777.myportfolio.HeroSectionLayoutStyle
import com.binayshaw7777.myportfolio.Rotate
import com.binayshaw7777.myportfolio.TextAnimationKeyframes
import com.binayshaw7777.myportfolio.components.widgets.SocialMediaIcons
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.utils.Strings
import com.binayshaw7777.myportfolio.widgets.AppearanceAwareImage
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@Composable
fun Hero() {
    Column(
        modifier = HeroSectionLayoutStyle.toModifier(),
        verticalArrangement = Arrangement.SpaceEvenly,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        val breakpoint = rememberBreakpoint()

        Spacer()

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {
                SpanText(
                    text = Strings.HI_IM,
                    modifier = HeroSectionFontStyle.toModifier()
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
                SpanText(
                    text = Strings.BINAY_SHAW,
                    modifier = HeroSectionFontStyle.toModifier()
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
                SpanText(
                    text = Strings.NICE_TO_MEET_YOU,
                    modifier = Modifier.fontSize(20.px)
                        .color(Color.rgb(0x909090))
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
            }
            Spacer()

            if (breakpoint >= Breakpoint.MD) {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier.fillMaxHeight()
                ) {
                    AppearanceAwareImage(
                        src = Res.SCROLL_DOWN_CIRCULAR_TEXT,
                        modifier = Modifier
                            .animation(
                                Rotate.toAnimation(
                                    null,
                                    duration = 10.s,
                                    timingFunction = AnimationTimingFunction.Linear,
                                    iterationCount = AnimationIterationCount.Infinite
                                )
                            )
                    )
                    AppearanceAwareImage(
                        src = Res.SCROLL_DOWN_SWITCH_ICON
                    )
                }
            }

        }
        Spacer()
        SocialMediaIcons()
    }
}